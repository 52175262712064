<template>
  <div v-if="d_userShopcartBundleProductPaymentWdm">
    <b-row>
      <b-col sm="12" lg="1"></b-col>
      <b-col sm="12" lg="10">
        <b-row style="margin: 0px; border-bottom: solid 1px #6bddcd;">
          <b-col sm="12" lg="6">
            {{ StoreLangTranslation.data['create_date'][StoreLang] }}
          </b-col>
          <b-col sm="12" lg="6">
            {{ p_paymentData['created_at'] }}
          </b-col>
        </b-row>
        <b-row style="margin: 0px; border-bottom: solid 1px #6bddcd;">
          <b-col sm="12" lg="6">
            {{ StoreLangTranslation.data['updated_at'][StoreLang] }}
          </b-col>
          <b-col sm="12" lg="6">
            {{ p_paymentData['updated_at'] }}
          </b-col>
        </b-row>
        <b-row style="margin: 0px; border-bottom: solid 1px #6bddcd;">
          <b-col sm="12" lg="6">
            {{ d_userShopcartBundleProductPaymentWdm['0']['parameters']['0']['name']['translation'][StoreLang] }}
          </b-col>
          <b-col sm="12" lg="6">
            {{ f_getOptionLabel(p_paymentData['data']['0']['0']['val']['value'], d_userShopcartBundleProductPaymentWdm['0']['parameters']['0']['options']) }}
          </b-col>
        </b-row>
        <b-row style="margin: 0px; border-bottom: solid 1px #6bddcd;">
          <b-col sm="12" lg="6">
            {{ d_userShopcartBundleProductPaymentWdm['0']['parameters']['1']['name']['translation'][StoreLang] }}
          </b-col>
          <b-col sm="12" lg="6">
            {{ f_getOptionLabel(p_paymentData['data']['0']['1']['val']['value'], d_userShopcartBundleProductPaymentWdm['0']['parameters']['1']['options']) }}
          </b-col>
        </b-row>
        <b-row style="margin: 0px; border-bottom: solid 1px #6bddcd;">
          <b-col sm="12" lg="6">
            {{ d_userShopcartBundleProductPaymentWdm['0']['parameters']['2']['name']['translation'][StoreLang] }}
          </b-col>
          <b-col sm="12" lg="6">
            {{ f_getOptionLabel(p_paymentData['data']['0']['2']['val']['value'], d_userShopcartBundleProductPaymentWdm['0']['parameters']['2']['options']) }}
          </b-col>
        </b-row>
        <b-row style="margin: 0px; border-bottom: solid 1px #6bddcd;">
          <b-col sm="12" lg="6">
            {{ d_userShopcartBundleProductPaymentWdm['0']['parameters']['3']['name']['translation'][StoreLang] }}
          </b-col>
          <b-col sm="12" lg="6">
            {{ f_getOptionLabel(p_paymentData['data']['0']['3']['val']['value'], d_userShopcartBundleProductPaymentWdm['0']['parameters']['3']['options']) }}
          </b-col>
        </b-row>
        <b-row style="margin: 0px; border-bottom: solid 1px #6bddcd;">
          <b-col sm="12" lg="6">
            {{ d_userShopcartBundleProductPaymentWdm['0']['parameters']['4']['name']['translation'][StoreLang] }}
          </b-col>
          <b-col sm="12" lg="6">
            <template v-if="p_paymentData['data']['0']['4'] && p_paymentData['data']['0']['4'].val">
              {{ p_paymentData['data']['0']['4'].val }}
            </template>
          </b-col>
        </b-row>
        <b-row style="margin: 0px; border-bottom: solid 1px #6bddcd;">
          <b-col sm="12" lg="6">
            {{ d_userShopcartBundleProductPaymentWdm['0']['parameters']['5']['name']['translation'][StoreLang] }}
          </b-col>
          <b-col sm="12" lg="6">
            <template v-if="p_paymentData['data']['0']['5'] && p_paymentData['data']['0']['5'].val">
              {{ p_paymentData['data']['0']['5'].val }}
            </template>
          </b-col>
        </b-row>
        <b-row style="margin: 0px; border-bottom: solid 1px #6bddcd;">
          <b-col sm="12" lg="6">
            {{ d_userShopcartBundleProductPaymentWdm['0']['parameters']['6']['name']['translation'][StoreLang] }}
          </b-col>
          <b-col sm="12" lg="6">
            <template v-if="p_paymentData['data']['0']['6'] && p_paymentData['data']['0']['6'].val">
              {{ p_paymentData['data']['0']['6'].val }}
            </template>
          </b-col>
        </b-row>
        <b-row style="margin: 0px; border-bottom: solid 1px #6bddcd;">
          <b-col sm="12" lg="6">
            {{ d_userShopcartBundleProductPaymentWdm['0']['parameters']['7']['name']['translation'][StoreLang] }}
          </b-col>
          <b-col sm="12" lg="6">
            <template v-if="p_paymentData['data']['0']['7'] && p_paymentData['data']['0']['7'].val">
              {{ p_paymentData['data']['0']['7'].val }}
            </template>
          </b-col>
        </b-row>
        <b-row style="margin: 0px; border-bottom: solid 1px #6bddcd;">
          <b-col sm="12" lg="6">
            {{ d_userShopcartBundleProductPaymentWdm['0']['parameters']['8']['name']['translation'][StoreLang] }}
          </b-col>
          <b-col sm="12" lg="6">
            <template v-if="p_paymentData['data']['0']['8'] && p_paymentData['data']['0']['8'].val">
              {{ p_paymentData['data']['0']['8'].val }}
            </template>
          </b-col>
        </b-row>
        <b-row style="margin: 0px; border-bottom: solid 1px #6bddcd;">
          <b-col sm="12" lg="6">
            {{ d_userShopcartBundleProductPaymentWdm['0']['parameters']['9']['name']['translation'][StoreLang] }}
          </b-col>
          <b-col sm="12" lg="6">
            <template v-if="p_paymentData['data']['0']['9'] && p_paymentData['data']['0']['9'].val">
              {{ p_paymentData['data']['0']['9'].val }}
            </template>
          </b-col>
        </b-row>
        <b-row style="margin: 0px; border-bottom: solid 1px #6bddcd;">
          <b-col sm="12" lg="6">
            {{ d_userShopcartBundleProductPaymentWdm['0']['parameters']['10']['name']['translation'][StoreLang] }}
          </b-col>
          <b-col sm="12" lg="6">
            <template v-if="p_paymentData['data']['0']['10'] && p_paymentData['data']['0']['10'].val">
              {{ p_paymentData['data']['0']['10'].val }}
            </template>
          </b-col>
        </b-row>
        <b-row style="margin: 0px; border-bottom: solid 1px #6bddcd;">
          <b-col sm="12" lg="6">
            {{ d_userShopcartBundleProductPaymentWdm['0']['parameters']['11']['name']['translation'][StoreLang] }}
          </b-col>
          <b-col sm="12" lg="6">
            <template v-if="p_paymentData['data']['0']['11'] && p_paymentData['data']['0']['11'].val">
              {{ p_paymentData['data']['0']['11'].val }}
            </template>
          </b-col>
        </b-row>
        <b-row style="margin: 0px; border-bottom: solid 1px #6bddcd;">
          <b-col sm="12" lg="6">
            {{ d_userShopcartBundleProductPaymentWdm['0']['parameters']['12']['name']['translation'][StoreLang] }}
          </b-col>
          <b-col sm="12" lg="6">
            <template v-if="p_paymentData['data']['0']['12'] && p_paymentData['data']['0']['12'].val">
              {{ p_paymentData['data']['0']['12'].val }}
            </template>
          </b-col>
        </b-row>
        <b-row style="margin: 0px; border-bottom: solid 1px #6bddcd;">
          <b-col sm="12" lg="6">
            {{ d_userShopcartBundleProductPaymentWdm['0']['parameters']['13']['name']['translation'][StoreLang] }}
          </b-col>
          <b-col sm="12" lg="6">
            <template v-if="p_paymentData['data']['0']['13'] && p_paymentData['data']['0']['13'].val">
              {{ p_paymentData['data']['0']['13'].val }}
            </template>
          </b-col>
        </b-row>
        <b-row style="margin: 0px; border-bottom: solid 1px #6bddcd;">
          <b-col sm="12" lg="6">
            {{ d_userShopcartBundleProductPaymentWdm['0']['parameters']['14']['name']['translation'][StoreLang] }}
          </b-col>
          <b-col sm="12" lg="6">
            <template v-if="p_paymentData['data']['0']['14'] && p_paymentData['data']['0']['14'].val">
              {{ p_paymentData['data']['0']['14'].val }}
            </template>
          </b-col>
        </b-row>
        <b-row style="margin: 0px; border-bottom: solid 1px #6bddcd;">
          <b-col sm="12" lg="6">
            {{ d_userShopcartBundleProductPaymentWdm['0']['parameters']['15']['name']['translation'][StoreLang] }}
          </b-col>
          <b-col sm="12" lg="6">
            <template v-if="p_paymentData['data']['0']['15'] && p_paymentData['data']['0']['15'].val">
              {{ p_paymentData['data']['0']['15'].val }}
            </template>
          </b-col>
        </b-row>
        <b-row style="margin: 0px; border-bottom: solid 1px #6bddcd;">
          <b-col sm="12" lg="6">
            {{ d_userShopcartBundleProductPaymentWdm['0']['parameters']['16']['name']['translation'][StoreLang] }}
          </b-col>
          <b-col sm="12" lg="6">
            <template v-if="p_paymentData['data']['0']['16'] && p_paymentData['data']['0']['16'].val">
              {{ p_paymentData['data']['0']['16'].val }}
            </template>
          </b-col>
        </b-row>
      </b-col>
      <b-col sm="12" lg="1"></b-col>
    </b-row>
  
  </div>
</template>

<script>
import { ClsWdm } from '@/wam/modules/wdm';
import { mapGetters } from 'vuex';
export default {
  name: 'ShopcardBundleProductPaymentShow',
  components: {},
  computed: mapGetters({
    StoreLang: 'StoreLang',
    StoreDevice: 'StoreDevice',
    StoreLangList: 'StoreLangList',
    StoreLangTranslation: 'StoreLangTranslation',
    StoreFrontendSettings: 'StoreFrontendSettings',
    StoreShopCartOrderList: 'StoreShopCartOrderList',
  }),
  props: {
    p_paymentData: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      d_userShopcartBundleProductPaymentWdm: '',
    }
  },
  created: function () {
    ClsWdm.getLayerWdm('usr_shpcrt_bund_pro_pay').then(resp_wdm_data => { this.d_userShopcartBundleProductPaymentWdm = resp_wdm_data; });
  },
  beforeMount () {},
  mounted: function () {},
  methods: {
    f_calculateCurrencyAmountItemStyle: function (currency_amount_index) {
      let style = 'margin: 3px;';
      if (currency_amount_index % 2 === 0) {
        style += 'background-color: #99e4e8';
      } else {
        style += 'background-color: #99e4e8';
        // style += 'background-color: white;';
      }
      return style;
    },
    f_getOptionLabel: function (option_value, options) {
      // console.log('option_value : ', option_value);
      // console.log('options : ', options);
      let option_label = '';
      for (let i in options) {
        if (options[i].value === option_value) {
          option_label = options[i].translation[this.StoreLang];
          break;
        }
      }
      return option_label;
    }
  },
  watch: {}
}

</script>

<style type="text/css">


</style>

